import axios from 'axios'
// 设置默认超时时间
axios.defaults.timeout = 15000
axios.defaults.withCredentials = true

// 拦截器
axios.interceptors.request.use(
  (config) => {
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error.response)
  }
)
export const $axios = axios

export default {
  install: function (vm) {
    vm.prototype.$axios = axios
  }
}