<template>
  <div id="app" v-if="src">
    <iframe :src="src" id="custom-iframe" frameborder="0"></iframe>
  </div>
</template>

<script>
import cookie from 'js-cookie'
export default {
  name: 'App',
  data () {
    return {
      src: '',
      loginPageUrl: ''
    }
  },
  async mounted() {
    await this.getInitConfig()
    this.$axios.get('/userAuth/main/checkUserAuth', {
      params: {
        session: cookie.get('SID') || '',
        serviceName: 'Guides'
      }
    }).then(res=>{
      const { errorCode } = res.data
      if (errorCode == '0x0') {
        // URL
        const origin = window.location.href.split('origin=')[1] || 'https://www.tvunetworks.com'
        // https://guides-dev.tvunetworks.com/?origin=https://www.tvunetworks.com
        this.src = `${origin}/tvu-products-user-guides`
        console.info(this.src)
      }
      if(errorCode == '0x80060005') {
        window.location.href = `${this.loginPageUrl}/?url=${location.protocol}//${location.host}&serviceName=Guides`
      }
    })
  },
  methods: {
    async getInitConfig () {
      await this.$axios.get('/login-service/init/initConfig').then(res=>{
        const { errorCode, result } = res.data
        if (errorCode == '0x0') {
          this.loginPageUrl = result.loginPageUrl
        }
      })
    }
  }
}

</script>
<style>
body {
  margin: 0;
}
#app, #custom-iframe {
  width: 100vw;
  height: 100vh;
  vertical-align: top;
}
</style>
